import React from 'react';
import '../../App.css';
import '../styles/Immuta.css';

function PreviousCareer() {

    return (
        <>
            <body>
                <div id='career-1'>
                    <div className='grid-title'>
                        Westglades Middle School
                    </div>
                    <div className='center'>
                        <div>
                            <br />
                            <h3>
                                Band Director &nbsp; August 2017 - June 2018 &nbsp; Parkland, FL
                            </h3>
                        </div>
                    </div>

                    <div className='exp-box'>
                        <br />
                        * coming soon!
                        <br /><br />
                    </div>
                </div>

                <div id='career-2'>
                    <div className='grid-title'>
                        DuBose Middle School
                    </div>
                    <div className='center'>
                        <div>
                            <br />
                            <h3>
                                Band Director &nbsp; August 2018 - June 2019 &nbsp; Summerville, SC
                            </h3>
                        </div>
                    </div>

                    <div className='exp-box'>
                        <br />
                        * coming soon!
                        <br /><br />
                    </div>
                </div>

                <div id='career-3'>
                    <div className='grid-title'>
                        Freelance Oboist / Bogdan Reeds
                    </div>
                    <div className='center'>
                        <div>
                            <br />
                            <h3>
                                June 2019 - September 2020 &nbsp; Charleston, SC / Columbus, OH
                            </h3>
                        </div>
                    </div>

                    <div className='exp-box'>
                        <br />
                        * coming soon!
                        <br /><br />
                    </div>
                </div>

            </body>

        </>
    );
}
export default PreviousCareer;



